@tailwind components;
@tailwind utilities;

@layer utilities {
    .tw-overflow-initial {
        overflow: initial;
    }
}


.color-yellow-highlight {
    background-color: #FEF28A;
}

.color-blue-button-background {
    color: #3B81D6;
}

.input-gray-border {
    border-color: #676767;
}

.modal-gray-background {
    background-color: #D9D9D9 !important;
}

.required-attribute {
    color: #FF0C0C !important;
}

.button-red-color {
    background-color: #F64148 !important;
}

.border-gray {
    border-color: #C7C7C7;
}

.gray-border {
    border: 1px solid #C7C7C7;
}

.checkbox-gray {
    border: 1px solid #CCCCCC;
}

.info-icon {
    color: #0D83DD !important;
}

.input-error {
    border: 1px solid #FF0004;
    background-color: #FFE0E0 !important;
}

.master-error-body-text {
    color: #D4454A !important;
    font-weight: normal;
}

.master-error-block {
    background-color: #fef0ed;
    border: 1px solid #f7765c;
}

.master-error-block-color {
    color: #f22900 !important;
}

.master-error-block-close {
    color: #EB6644 !important;
}

.master-error-block-close:hover {
    color: #f0311e !important;
}


.magic-strings-border:focus-visible {
    border: 1px solid #136FBE;
    outline: none;
}

.gray-text {
    color: #898989;
}

.invoice-accordion-container-collapsed {
}

.invoice-accordion-container-expanded {
    @apply tw-outline tw-outline-2 tw-outline-[#DCDCDC];
}

.invoice-accordion-header-expanded {
    @apply tw-bg-[#D9D9D9];
}

.invoice-accordion-header-collapsed {
}

.invoice-accordion-footer-collapsed {
    @apply tw-pl-[15px];
}

.invoice-accordion-footer-expanded {
    @apply tw-pl-[75px];
}

.box-sizing, .box-sizing * {
    box-sizing: border-box;
}

.border-top-black {
    border-top: 1px solid #000000;
}

.accepted-block {
    background-color: #C6F6BB !important;
    color: #006D18 !important;
}

.rejected-block {
    background-color: #FFE0E0 !important;
    color: #FF0004 !important;
}

.sent-block {
    background-color: #FDDE9F !important;
    color: #FE6E4E !important;
}

.background-blue-info {
    background-color: #def1ff;
    color: #2F91E5;
}

.background-blue-button {
    border-color: #3491D9;
    background-color: #CCE4F5;
    color: #127FE4;
}

.background-blue-button:hover {
    background-color: #99C9ED;
}

.border-required {
    border-color: #CE3B34;
}

.close-icon-gray {
    color: #6B6B6B;
}

.border-bottom-gray {
    border-bottom-style: solid;
    border-bottom-color: #8E8E8E;
}

.border-bottom-light-gray {
    border-bottom-style: solid;
    border-bottom-color: #C7C7C7;
}

.recipient-block:hover {
    background-color: #def1ff;
    border: 1px solid #5f9fd0;
}

.focused-block {
    background-color: #def1ff;
    border: 1px solid #5f9fd0;
}

.text-gray {
    color: #666;
}

.background-gray {
    background-color: #F7F7F7;
}

.background-yellow {
    background-color: #F6F0BB;
}

.button-blue-border {
    background-color: #FFF !important;
    border: 1px solid #2F91E5 !important;
    color: #2F91E5 !important;
}

.background-red {
    background-color: #FFEFEF;
}

.button-error-red {
    color: #EB6644 !important;
}

.text-red {
    color: #D4454A;
}

.border-top-white {
    border-top: 3px solid #FFF;
}

#send-invoices-table tr.checked td {
    background-color: #ECECEC !important;
}

#send-invoices-table tr.hover td {
    background-color: #fffae7 !important;
}

.status-paid {
    color: #006b08;
}

.status-partially-paid {
    color: #fe6f4b;
}

.status-unpaid {
    color: #ff0000;
}
